
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";

import SelectInput from "../../../shared/components/inputs/SelectInput";
import {
  GetPOUser
} from "../../../infra/requests/InternalPORequests";
import { useSerializeToUrl, queryByStep }
  from "../../../shared/utils/serializeToUrl";




const UserFilter = ({ filters, handleChange, step = 0 }) => {
  const [userList, setUserList] = useState([]);
  const serializeToUrl = useSerializeToUrl(queryByStep[step]);

  useEffect(() => {
    async function fetchPOUsers() {
      const { data } = await GetPOUser(serializeToUrl);
      const sortedData = data.sort((a, b) => a.fullName.localeCompare(b.fullName));

      setUserList([
        { id: '0', name: 'All' },
        ...sortedData.map((op) => ({ id: op.userId, name: op.fullName }))
      ]);
    }
    fetchPOUsers();
  }, []);


  return (
    <SelectInput
      input={{
        value: filters.user,
        onChange: (value) => handleChange("user", value),
      }}
      label={<Translate id="USER" />}
      placeholder={<Translate id="SELECT" />}
      data={userList}
      dataKey="id"
      dataLabel="name"
    />

  );
};

UserFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default UserFilter;
