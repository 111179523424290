import React, { useEffect, useState } from 'react'

import { BackwardOutlined, ForwardOutlined } from '@ant-design/icons'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  initialize,
  Field,
  reduxForm,
  formValueSelector,
  change
} from 'redux-form'

import NeedsMapHeader from './NeedsMapHeader'
import DropdownIcon from '../../../assets/icons/dropdown_blue.svg'
import EmptyStateIcon from '../../../assets/icons/empty_state.svg'
import {
  GetNeedsMap,
  SaveNeedsMap,
  ConvertToSupplierOrders
} from '../../../infra/requests/InternalPORequests'
import { RevertOrderNeedsCreation } from '../../../infra/requests/SupplierOrdersRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import SwitchInput from '../../../shared/components/inputs/SwitchInput'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import BaseTable from '../../../shared/components/table/BaseTable'
import RoundCurrency from '../../../shared/logic/numbers/RoundCurrency'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { ToggleImage, Flex } from '../../../shared/styles/BasicStyles'
import {
  POBaseTitle,
  POOrderNumber,
  POBaseContainer,
  POHeaderCollapsable,
  POLeftContainer,
  POTableContainer,
  POTableFooter,
  POTotalSection,
  FooterLine,
  ReviewToggle,
  ValidationSection,
  ValidationMessage,
  EmptyContainer,
  EmptyMessage,
  EmptyIcon,
  MessageTrigger,
  Message
} from '../components/POStyles'


import UserFilter from '../components/UserFilter'

const TransformPOList = (list) => {
  if (list && list.length) {
    return list.map((po) => ({
      ...po,
      orderPoneedService: po.orderPoneedService.map((service) => ({
        ...service,
        oldSupplierId: service.supplierId
      }))
    }))
  }
  return []
}

const NeedsMap = ({ POList, dispatch, setSection, onChangeTab }) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [filters, setFilters] = useState({
    user: ''
  });


  const handleChange = (key, value) => {
    setFilters((prevFilters) => {
      const change = { ...prevFilters };
      change[key] = value;

      return change;
    });
  };

  let completed = true
  POList.forEach((elem) => {
    if (!elem.needsReviewed) completed = false
  })

  useEffect(() => {
    async function fetchPOs() {
      const { data } = await GetNeedsMap(filters.user)
      dispatch(
        initialize('manage_needs_map', {
          POList: TransformPOList(data?.items)
        })
      )
      setLoading(false)
    }
    fetchPOs()
  }, [filters.user])

  const openPO = (index) => {
    dispatch(
      change(
        'manage_needs_map',
        `POList[${index}].open`,
        !POList[index].open
      )
    )
  }

  const toggleReview = (POIndex) => {
    dispatch(
      change('manage_needs_map', `POList[${POIndex}].needsReviewed`, false)
    )
  }

  const renderSupplierSelect = (data, POIndex, arrIndex) => {
    if (data.serviceId) {
      const index = arrIndex - POList[POIndex].orderPoneed.length
      return (
        <Field
          component={SelectInput}
          small
          name={`POList[${POIndex}].orderPoneedService[${index}].supplierId`}
          data={data?.alternateSuppliers || []}
          dataKey='supplierId'
          dataLabel='name'
          allowClear={false}
          afterChange={() => toggleReview(POIndex)}
        />
      )
    }
    return (
      <Field
        component={SelectInput}
        small
        name={`POList[${POIndex}].orderPoneed[${arrIndex}].supplierId`}
        data={data?.product?.productSupplier || []}
        dataKey='supplierId'
        dataLabel='supplier.name'
        allowClear={false}
        afterChange={() => toggleReview(POIndex)}
      />
    )
  }

  const renderDescription = (data) => {
    if (data.serviceId) {
      const color = data?.productCombination?.color?.colorTranslation
      const size = data?.productCombination?.size?.nameEu
      const width =
        data?.productCombination?.productWidth?.productWidthTranslation

      return (
        <div>
          <ActiveTranslation
            value={data?.s?.service?.serviceTranslation}
            tag='name'
          />{' '}
          (
          <span>
            <ActiveTranslation value={color} tag='name' />
          </span>
          , <span>{size}</span>,{' '}
          <span>
            <ActiveTranslation value={width} tag='name' />
          </span>
          )
        </div>
      )
    }
    return (
      <ActiveTranslation
        value={data?.product?.productTranslation}
        tag='name'
      />
    )
  }

  const renderTotalValue = (data) => {
    if (data.serviceId) {
      if (data.currencyID !== 1) {
        return `${RoundCurrency(data?.quantity * data?.unitCost * data.exchangeRate)}€`
      } return `${RoundCurrency(data?.quantity * data?.unitCost)}€`
    }
    if (data.currencyID !== 1) {
      return `${RoundCurrency(data?.quantity * data?.product?.tableValue * data.exchangeRate)}€`
    } return `${RoundCurrency(data?.quantity * data?.product?.tableValue)}€`
  }

  const renderColumns = (POIndex, PO) => {
    const columns = [
      {
        type: 'text',
        title: <Translate id='SUPPLIER' />,
        render: (text, row, index) =>
          renderSupplierSelect(row, POIndex, index)
      },
      {
        type: 'text',
        title: <Translate id='DESCRIPTION' />,
        render: renderDescription
      },
      {
        type: 'text',
        title: <Translate id='MOQ' />,
        render: (data) =>
          data.serviceId ? data.moq || '-' : data.product?.moq || '-'
      },
      {
        type: 'text',
        title: <Translate id='MAQ' />,
        render: (data) =>
          data.serviceId ? data.maq || '-' : data.product?.maq || '-'
      },
      {
        type: 'text',
        dataIndex: 'quantity',
        title: <Translate id='ORDER_QTY' />
      },
      {
        type: 'text',
        title: <Translate id='STOCK' />,
        render: (data) => (data.serviceId ? '-' : data?.product?.stock || 0)
      },
      {
        type: 'text',
        title: <Translate id='UM' />,
        render: (data) =>
          data.serviceId ? 'units' : data?.product?.measureUnit?.code || '-'
      },
      {
        type: 'text',
        title: <Translate id='TABLE_VALUE_EUR' />,
        render: (data) =>
          data.serviceId
            ? `${RoundCurrency(data.unitCost * data.exchangeRate)}€`
            : `${RoundCurrency(data.product?.tableValue * data.exchangeRate)}€`
      },
      {
        type: 'text',
        title: <Translate id='WEIGHTED_COST_EUR' />,
        render: (data) =>
          data.serviceId
            ? `${RoundCurrency(data.unitCost * data.exchangeRate)}€`
            : `${RoundCurrency(data.product?.costValue * data.exchangeRate)}€`
      },
      {
        type: 'text',
        title: <Translate id='MANUF_C_LAST_EUR' />,
        render: (data) =>
          data.serviceId
            ? `${RoundCurrency((data.lastPrice || data.unitCost || 0) * data.exchangeRate)}€`
            : `${RoundCurrency(data.product?.tableValue * data.exchangeRate)}€`
      },
      {
        type: 'text',
        title: <Translate id='PRICE_EUROS' />,
        render: renderTotalValue
      }
    ]

    const currency = PO.orderPoneed.find((i) => i.currencyID !== 1) ||
      PO.orderPoneedService.find((i) => i.currencyID !== 1)

    if (currency) {
      columns.push({
        type: 'text',
        title: <Translate id='TABLE_VALUE' />,
        render: (data) => data.currencyID !== 1 ?
          data.serviceId
            ? `${RoundCurrency(data.unitCost)}`
            : `${RoundCurrency(data.product?.tableValue)}` : '-'
      })
      columns.push({
        type: 'text',
        title: <Translate id='WEIGHTED_COST' />,
        render: (data) => data.currencyID !== 1 ?
          data.serviceId
            ? `${RoundCurrency(data.unitCost)}`
            : `${RoundCurrency(data.product?.costValue)}` : '-'
      })
      columns.push({
        type: 'text',
        title: <Translate id='MANUF_C_LAST' />,
        render: (data) => data.currencyID !== 1 ?
          data.serviceId
            ? `${RoundCurrency(data.lastPrice || data.unitCost || 0)} ${data.currencyCode}`
            : `${RoundCurrency(data.product?.tableValue)} ${data.currencyCode}` : '-'
      })
      columns.push({
        type: 'text',
        title: <Translate id='PRICE' />,
        render: (data) => data.currencyID !== 1 ?
          data.serviceId
            ? `${RoundCurrency(data?.quantity * data?.unitCost)}`
            : `${RoundCurrency(data?.quantity * data?.product?.tableValue)}`
          : '-'
      })
    }

    return columns
  }

  const savePO = async (value, index) => {
    dispatch(
      change('manage_needs_map', `POList[${index}].needsReviewed`, value)
    )
    const po = {
      OrderPoid: POList[index].orderPoid,
      needsReviewed: value,
      OrderPoneed: POList[index].orderPoneed.map((need) => ({
        OrderPoid: need.orderPoid,
        ProductId: need.product?.productId,
        SupplierId: need.supplierId,
        ColorId: need.colorId,
        SizeId: need.sizeId
      })),
      orderPoneedService: POList[index].orderPoneedService.map(
        (service) => ({
          OrderPoid: service.orderPoid,
          ServiceId: service.serviceId,
          SupplierId: service.oldSupplierId,
          NewSupplierId: service.supplierId,
          ProductId: service.productId,
          SizeId: service.sizeId,
          ColorId: service.colorId,
          ProductWidthId: service.productWidthId
        })
      )
    }
    const { success } = await SaveNeedsMap(POList[index].orderPoid, po)
    if (!success) {
      dispatch(
        change('manage_needs_map', `POList[${index}].needsReviewed`, false)
      )
      return { hasErrors: true }
    }
    return { haseErrors: false }
  }

  const transferToConsolidation = async () => {
    if (completed) {
      setSaving(true)
      const payload = POList.map((po) => po.orderPoid)
      const { success } = await ConvertToSupplierOrders(payload)
      setSaving(false)
      if (success) setSection(1)
    }
  }

  const revertPO = async (po) => {
    const { success } = await RevertOrderNeedsCreation(po.orderPoid)
    if (success) {
      // Fetch data again after reverting
      const { data } = await GetNeedsMap()
      dispatch(
        initialize('manage_needs_map', {
          POList: TransformPOList(data?.items)
        })
      )
    }
  }

  const evolvePO = async (po, index) => {
    setSaving(true)
    const { hasErrors } = await savePO(true, index)
    if (!hasErrors) {
      const payload = [po.orderPoid]
      const { success } = await ConvertToSupplierOrders(payload)
      setSaving(false)
      if (success) setSection(1)
    } else {
      setSaving(false)
    }
  }

  if (loading) return <BaseLoading margin={100} />

  if (POList.length === 0) {
    return (
      <Row gutter={[16, 16]} >
        <Col xs={24}>
          <Row justify="space-between" align="middle">
            <Col xs={24} md={6}>
              <UserFilter filters={filters} handleChange={handleChange} step={1} />
            </Col>
            <Col >
              <ValidationSection>
                <BaseButton type='primary' onClick={() => setSection(1)} auto>
                  <Translate id='NEXT' />
                </BaseButton>
              </ValidationSection>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <EmptyContainer>
            <EmptyMessage>
              <Message>
                <Translate id='NEEDS_MAP_EMPTY_DESC1' />
              </Message>
              <MessageTrigger onClick={() => onChangeTab('open')}>
                <Translate id='OPEN_PO' />
              </MessageTrigger>
              <Message>
                <Translate id='NEEDS_MAP_EMPTY_DESC2' />
              </Message>
            </EmptyMessage>
            <EmptyIcon src={EmptyStateIcon} />
          </EmptyContainer>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col xs={24} sm={12} md={8} lg={6}>
          <UserFilter filters={filters} handleChange={handleChange} step={2} />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <ValidationSection>
            <BaseButton
              type='primary'
              onClick={transferToConsolidation}
              auto
              disabled={!completed}
              loading={saving}
            >
              <Translate id='CONTINUE' />
            </BaseButton>
            {!completed && (
              <ValidationMessage>
                <Translate id='ERROR_CONTINUE' />
              </ValidationMessage>
            )}
          </ValidationSection>
        </Col>
      </Row>
      {POList.map((PO, index) => (
        <Row key={index}>
          <Col xs={24}>
            <POBaseTitle>

              <Flex>
                <Translate id='INTERNAL_PO' />
                <POOrderNumber>
                  - <Translate id='ORDER' /> #{PO?.orderB2b?.number}
                </POOrderNumber>
              </Flex>

              <ReviewToggle>

                <BaseButton
                  style={{ marginRight: '20px' }}
                  auto
                  type='secondary'
                  loading={PO.loading}
                  onClick={() => revertPO(PO)}
                >
                  <Flex>
                    <div>

                      <BackwardOutlined />
                    </div>
                    <Translate id='REVERT' />
                  </Flex>
                </BaseButton>

                <BaseButton
                  style={{ marginRight: '20px' }}
                  auto
                  type='secondary'
                  loading={PO.loading || saving}
                  onClick={() => evolvePO(PO, index)}
                >
                  <Flex>
                    <Translate id='EVOLVE' />
                    <div>
                      <ForwardOutlined style={{ marginRight: 0, marginLeft: 10 }} />
                    </div>
                  </Flex>
                </BaseButton>

                <SwitchInput
                  input={{
                    value: PO?.needsReviewed,
                    onChange: (value) => savePO(value, index)
                  }}
                  checkedText={<Translate id='REVIEWED' />}
                  uncheckedText={<Translate id='UNREVIEWED' />}
                />
              </ReviewToggle>
            </POBaseTitle>
            <POBaseContainer>
              <POHeaderCollapsable onClick={() => openPO(index)}>
                <POLeftContainer>
                  <ToggleImage
                    $marginTop
                    src={DropdownIcon}
                    $open={PO.open}
                  />
                  <NeedsMapHeader PO={PO} />
                </POLeftContainer>
              </POHeaderCollapsable>
              <POTableContainer open={PO.open}>
                {PO.open && (
                  <BaseTable
                    rowKey={(data) =>
                      data?.serviceId
                        ? `service_${data?.serviceId}_${data?.colorId}_${data?.sizeId}`
                        : `material${data?.product?.productId}_${data?.colorId}_${data?.sizeId}`
                    }
                    columns={renderColumns(index, PO)}
                    datasource={[
                      ...PO.orderPoneed,
                      ...PO.orderPoneedService
                    ]}
                    pagination={{ render: false }}
                    scroll={{ x: true }}
                  />
                )}
                <POTableFooter>
                  <POTotalSection>
                    <FooterLine>
                      <Translate id='TOTAL_ORDER_QTY' />:{' '}
                      <span>{PO?.totalQuantity}</span>
                    </FooterLine>
                    <FooterLine bold>
                      <Translate id='TOTAL_PRICE' />:{' '}
                      <span>
                        {RoundCurrency(PO.totalValue || 0)}€
                      </span>
                    </FooterLine>
                  </POTotalSection>
                </POTableFooter>
              </POTableContainer>
            </POBaseContainer>
          </Col>
        </Row>
      ))}

    </>
  )
}

NeedsMap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  POList: PropTypes.array.isRequired,
  setSection: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired
}

NeedsMap.defaultProps = {}

const myComponent = reduxForm({
  form: 'manage_needs_map',
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(NeedsMap)

const selector = formValueSelector('manage_needs_map')

const mapStateToProps = (state) => ({
  POList: selector(state, 'POList') || [],
  initialValues: { POList: [] }
})

export default connect(mapStateToProps)(myComponent)
