import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import BaseSteps from '../../../shared/components/steps/BaseSteps'
import Confirmation from './Confirmation'
import Consolidation from './Consolidation'
import NeedsMap from './NeedsMap'
import { GetAuthUser } from '../../../infra/requests/BaseRequests'

const Steps = [
  <Translate id='NEEDS_MAP' />,
  <Translate id='CONSOLIDATION' />,
  <Translate id='CONFIRMATION' />
]

const ProcessingPO = ({ onChangeTab }) => {
  const [section, setSection] = useState(0)


  return (
    <>
      <BaseSteps
        steps={Steps}
        currentStep={section}
        changeStep={setSection}
        max={2}
      />
      {section === 0 && (
        <NeedsMap setSection={setSection} onChangeTab={onChangeTab} />
      )}
      {section === 1 && (
        <Consolidation setSection={setSection} onChangeTab={onChangeTab} />
      )}
      {section === 2 && (
        <Confirmation setSection={setSection} onChangeTab={onChangeTab} />
      )}
    </>
  )
}

ProcessingPO.propTypes = {
  onChangeTab: PropTypes.func.isRequired
}

export default ProcessingPO
