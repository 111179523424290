export const useSerializeToUrl = (params) => {
    const validParams = Object.entries(params)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

    return validParams ? `?${validParams}` : '';
};

export const queryByStep = {
    0: { isDraft: true, needsSaved: false },
    1: { isDraft: false, needsSaved: false },
    2: { consolidated: false, confirmed: false },
    3: { consolidated: true, confirmed: false }
};
