import client from '../config/AxiosConfig'

export const GetOpenPO = async (id) => client.get(`OrderPO/GetOrdersPOOpen${id ? `/?filterbyUserId=${id}` : ''}`)
// filterbyUserId, 0 , nd - logged
export const CreateMapNeeds = async (id, data) =>
  client.post(`OrderPO/CreateOrderNeeds/${id}`, data)

export const GetNeedsMap = async (id) =>
  client.get(`OrderPO/GetOrdersPONeeds${id ? `/?filterbyUserId=${id}` : ''}`)

export const SaveNeedsMap = async (id, data) =>
  client.put(`OrderPO/UpdateOrderNeeds/${id}`, data)

export const ConvertToSupplierOrders = async (data) =>
  client.post('OrderSupplier/CreateOrdersSupplier', data)

export const GetConsolidation = async (id) =>
  client.get(`OrderSupplier/GetOrderSupplierSetsConsolidation${id ? `/?filterbyUserId=${id}` : ''}`)

export const SaveConsolidation = async (id, data) =>
  client.put(`OrderSupplier/SaveConsolidationOrderSupplierSet/${id}`, data)

export const SendToConfirmation = async (data) =>
  client.put('OrderSupplier/ConsolidateOrderSupplierSets', data)

export const GetConfirmation = async (id) =>
  client.get(`OrderSupplier/GetOrderSupplierSetsConfirmation${id ? `/?filterbyUserId=${id}` : ''}`)

export const SaveConfirmation = async (id, data, ignore = false) =>
  client.put(`OrderSupplier/SaveConfirmationOrderSupplierSet/${id}?IgnoreInvalidEmail=${ignore}`, data)

export const SendSupplierOrders = async (data) =>
  client.put('OrderSupplier/SendOrderSupplierSets', data)


export const GetPOUser = async (query) =>
  client.get(`OrderPO/GetOrderPoUsers/${query}`)